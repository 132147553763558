/**
 * Core theme script.
 *
 * @package Bamboo Theme
 */

//-----------------------------------------------------------------------------

ready(function() {

	// Prepend child menu buttons to menus.
	var menuItemsWithChildren = document.querySelectorAll('nav .menu-item-has-children');
	menuItemsWithChildren.forEach(element => {
		var button = document.createElement('span');
		button.classList.add('child-menu-button');
		element.prepend(button);
	});

	// Show sub-menu when mobile menu item is clicked.
	var childMenuButtons = document.querySelectorAll('nav.phone-and-tablet-only .child-menu-button');
	childMenuButtons.forEach(element => {
		element.addEventListener('click', () => {
			element.classList.toggle('active');
			var siblings = element.parentElement.children;
			var subMenu = siblings[siblings.length-1];
			subMenu.classList.toggle('active');
			if(subMenu.classList.contains('active')) {
				showMobileMenu( subMenu );
			} else {
				hideMobileMenu( subMenu );
			}
		});
	});

	// Show mobile menu when menu button is clicked.
	var menuButton = document.querySelectorAll('.menu-button')[0];
	var mobileMenu = document.querySelectorAll('nav.phone-and-tablet-only')[0];
	menuButton.addEventListener('click', () => {
		menuButton.classList.toggle('active');
		mobileMenu.classList.toggle('active');
	});

	// Show sub-menu when desktop menu item is hovered.
	var desktopMenuItemsWithChildren = document.querySelectorAll('nav.desktop-only .menu-item-has-children');
	desktopMenuItemsWithChildren.forEach(element => {
		element.addEventListener('mouseover', () => {
			showDesktopMenu( element );
		});
		element.addEventListener('mouseout', () => {
			hideDesktopMenu( element );
		});
	});

	// Enable click through on elements that contain links.
	var clickThroughElements = document.querySelectorAll('.click-through');
	clickThroughElements.forEach(element => {
		element.addEventListener('click', () => {
			var link = element.querySelector('a');
			if(link) {
				var url = link.getAttribute('href');
				if(url) {
					document.location.href = link;
				}
			}
		});
	});

	// Append navigation elements to carousels.
	var carousels = document.querySelectorAll('.carousel');
	carousels.forEach(carousel => {

		const carouselControls = document.createElement('div');
		carouselControls.classList.add('carousel-controls', 'narrow-width');

		const carouselIndicators = document.createElement('div');
		carouselIndicators.classList.add('carousel-indicators');
		carouselControls.append(carouselIndicators);

		const carouselButtonLeft = document.createElement('span');
		carouselButtonLeft.classList.add('carousel-button-left');
		carouselButtonLeft.addEventListener('click', (event) => {
			event.preventDefault();
			pageCarouselLeft(event.target.parentElement.parentElement);
		});
		carouselControls.append(carouselButtonLeft);

		const carouselCounter = document.createElement('div');
		carouselCounter.classList.add('carousel-counter');
		carouselControls.append(carouselCounter);

		const carouselButtonRight = document.createElement('span');
		carouselButtonRight.classList.add('carousel-button-right');
		carouselButtonRight.addEventListener('click', (event) => {
			event.preventDefault();
			pageCarouselRight(event.target.parentElement.parentElement);
		});
		carouselControls.append(carouselButtonRight);

		carousel.append(carouselControls);

	});

	// Initialise carousels.
	initialiseCarousels();
	window.onresize = initialiseCarousels;
	var carouselContents = document.querySelectorAll('.carousel-contents');
	carouselContents.forEach(carouselContent => {
		carouselContent.addEventListener('scroll', (event) =>{
			updateCarousel(event.target.parentElement);
		});
	});

	// Append navigation elements to sliders.
	var sliders = document.querySelectorAll('.slider');
	sliders.forEach(slider => {

		const sliderControls = document.createElement('div');
		sliderControls.classList.add('slider-controls');

		const sliderIndicators = document.createElement('div');
		sliderIndicators.classList.add('slider-indicators');
		sliderControls.append(sliderIndicators);

		const sliderButtonLeft = document.createElement('span');
		sliderButtonLeft.classList.add('slider-button-left');
		sliderButtonLeft.addEventListener('click', (event) => {
			event.preventDefault();
			sliderLeft(event.target.parentElement.parentElement);
		});
		sliderControls.append(sliderButtonLeft);

		const sliderCounter = document.createElement('div');
		sliderCounter.classList.add('slider-counter');
		sliderControls.append(sliderCounter);

		const sliderButtonRight = document.createElement('span');
		sliderButtonRight.classList.add('slider-button-right');
		sliderButtonRight.addEventListener('click', (event) => {
			event.preventDefault();
			sliderRight(event.target.parentElement.parentElement);
		});
		sliderControls.append(sliderButtonRight);

		slider.append(sliderControls);

	});

	// Initialise sliders.
	initialiseSliders();


	// Append navigation elements to image with text sliders.
	var sliders = document.querySelectorAll('.image-with-text-slider');
	sliders.forEach(slider => {

		const sliderControls = document.createElement('div');
		sliderControls.classList.add('slider-controls');

		const sliderIndicators = document.createElement('div');
		sliderIndicators.classList.add('slider-indicators');
		sliderControls.append(sliderIndicators);

		const sliderButtonLeft = document.createElement('span');
		sliderButtonLeft.classList.add('slider-button-left');
		sliderButtonLeft.addEventListener('click', (event) => {
			event.preventDefault();
			imageWithTextSliderLeft(event.target.parentElement.parentElement);
		});
		sliderControls.append(sliderButtonLeft);

		const sliderCounter = document.createElement('div');
		sliderCounter.classList.add('slider-counter');
		sliderControls.append(sliderCounter);

		const sliderButtonRight = document.createElement('span');
		sliderButtonRight.classList.add('slider-button-right');
		sliderButtonRight.addEventListener('click', (event) => {
			event.preventDefault();
			imageWithTextSliderRight(event.target.parentElement.parentElement);
		});
		sliderControls.append(sliderButtonRight);

		slider.append(sliderControls);

	});

	// Initialise image with text sliders.
	initialiseImageWithTextSliders();

	// Initialise Expand on Click elements.
	var expandOnClickElements = document.querySelectorAll('.expand-on-click');
	expandOnClickElements.forEach(element => {

		var text = element.innerHTML;
		element.setAttribute('data-text', text);

		var truncatedText = text.split(' ').slice(0,25).join(' ');
		truncatedText += '<span class="ellipsis">...</span><span class="hint">Click to read more</span>';
		element.setAttribute('data-truncated-text', truncatedText);

		element.innerHTML = truncatedText;

		element.addEventListener('click', () => {
			element.classList.toggle('expanded');
			if(element.classList.contains('expanded')) {
				element.innerHTML = element.getAttribute('data-text');
			} else {
				element.innerHTML = element.getAttribute('data-truncated-text');
			}
		});

	});

	// Initialise FAQs.
	var faqs = document.querySelectorAll('.faq');
	faqs.forEach(faq => {
		var answer = faq.querySelector('& > *:last-child');
		var height = (answer.offsetHeight);
		answer.setAttribute('data-height', height);
		answer.style.height = '0px';
		faq.addEventListener('click', () => {
			if(!faq.classList.contains('open')) {
				var allfaqs = faq.parentElement.querySelectorAll('.faq.open');
				allfaqs.forEach(allfaq => {
					allfaq.classList.remove('open');
					var allanswer = allfaq.querySelector('& > *:last-child');
					allanswer.style.height = '0px';
				});
			}
			var answer = faq.querySelector('& > *:last-child');
			faq.classList.toggle('open');
			if(faq.classList.contains('open')) {
				var height = answer.getAttribute('data-height');
				answer.style.height = height + 'px';
			} else {
				answer.style.height = '0px';
			}
		});
	});

	// Wrap all iframes in an iframe-container div.
	var iframes = document.querySelectorAll('iframe');
	iframes.forEach(iframe => {
		var container = document.createElement('div');
		container.classList.add('iframe-container');
		iframe.parentElement.insertBefore(container, iframe);
		container.appendChild(iframe);
	});

});

//-----------------------------------------------------------------------------

function ready(fn) {

	if (document.readyState !== 'loading') {
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}

}

//-----------------------------------------------------------------------------

function showDesktopMenu( element ) {

	element.classList.add('active');

	var menuHeight = 0;

	var subMenu = element.querySelector('ul');
	for(const child of subMenu.children) {
		const style = getComputedStyle(child);
		const height = child.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
		menuHeight += height;
	}
	subMenu.style.height = menuHeight + 'px';

}

//-----------------------------------------------------------------------------

function hideDesktopMenu( element ) {

	element.classList.remove('active');

	var subMenu = element.querySelector('ul');
	subMenu.style.height = '0px';

}

//-----------------------------------------------------------------------------

function showMobileMenu( subMenu ) {

	var menuHeight = 0;

	for(const child of subMenu.children) {
		const style = getComputedStyle(child);
		const height = child.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
		menuHeight += height;
	}
	subMenu.style.height = menuHeight + 'px';

}

//-----------------------------------------------------------------------------

function hideMobileMenu( subMenu ) {

	subMenu.style.height = '0px';

}

//-----------------------------------------------------------------------------

function initialiseCarousels() {

	// Do not initialise carousels on small screens.
	if (window.innerWidth <= 1170 ) return;

	//Establish the number of required indicators.
	var carousels = document.querySelectorAll('.carousel');
	carousels.forEach(carousel => {

		var pageCount = getCarouselPageCount(carousel);
		var currentPage = getCurrentCarouselPage(carousel);

		//Remove existing indicators.
		var indicators = carousel.querySelectorAll('.carousel-indicators > div');
		indicators.forEach(indicator => {
			indicator.remove();
		}
		);

		//Add new indicators.
		for (var i = 0; i < pageCount; i++) {
			const indicator = document.createElement('div');
			indicator.attributes['data-page'] = i;
			indicator.addEventListener('click', (event) => {
				event.preventDefault();
				//event.target.parentElement.parentElement.parentElememnt
				pageCarouselTo(carousel, event.target.attributes['data-page']);
			});
			carousel.querySelector('.carousel-indicators').append(indicator);
		}

		// Update counter.
		var counter = carousel.querySelector('.carousel-counter');
		counter.innerHTML = (currentPage+1) + '/' + pageCount;

		//Update carousel.
		updateCarousel(carousel);

	});

}

//-----------------------------------------------------------------------------

function updateCarousel(carousel) {

	// Do not update carousels on small screens.
	if (window.innerWidth <= 1170 ) return;

	var currentPage = getCurrentCarouselPage(carousel);
	var pageCount = getCarouselPageCount(carousel);
	var indicators = carousel.querySelectorAll('.carousel-indicators > div');

	indicators.forEach(indicator => {
		indicator.classList.remove('active');
	});

	indicators[currentPage].classList.add('active');

	var counter = carousel.querySelector('.carousel-counter');
	counter.innerHTML = (currentPage+1) + '/' + pageCount;

}


//-----------------------------------------------------------------------------

function pageCarouselTo(carousel, page) {

	var carouselWidth = getElementWidth(carousel);
	var items = carousel.querySelectorAll('.carousel-contents > div');
	var itemWidth = items[0].offsetWidth;
	var content = carousel.querySelector('.carousel-contents');
	var itemSpacing = parseInt(window.getComputedStyle(content).gap);
	var itemsPerPage = Math.floor(carouselWidth/(itemWidth+itemSpacing));
	var pageWidth = (itemWidth*itemsPerPage) + (itemSpacing*(itemsPerPage-1));
	var scrollLeft = pageWidth*page;

	content.scrollLeft = scrollLeft;
	updateCarousel(carousel);

}

//-----------------------------------------------------------------------------

function pageCarouselRight(carousel) {

	var currentPage = getCurrentCarouselPage(carousel);
	var lastPage = getCarouselPageCount(carousel) - 1;
	if(currentPage<lastPage) {
		pageCarouselTo(carousel, currentPage+1);
	}

}

//-----------------------------------------------------------------------------

function pageCarouselLeft(carousel) {
	var currentPage = getCurrentCarouselPage(carousel);
	if(currentPage>0) {
		pageCarouselTo(carousel, currentPage-1);
	}

}

//-----------------------------------------------------------------------------

function getCurrentCarouselPage(carousel) {

	var content = carousel.querySelector('.carousel-contents');
	var items = carousel.querySelectorAll('.carousel-contents > div');
	var itemCount = items.length;
	var itemWidth = items[0].offsetWidth;
	var itemSpacing = parseInt(window.getComputedStyle(content).gap);
	var carouselWidth = carousel.offsetWidth;
	var contentWidth = (itemWidth*itemCount) + (itemSpacing*(itemCount-1));
	var pageCount = getCarouselPageCount(carousel);
	var pageWidth = contentWidth/pageCount;
	var currentPage = Math.floor(content.scrollLeft/pageWidth);

	if(content.scrollLeft >= (contentWidth-carouselWidth)) {
		currentPage = pageCount - 1;
	}

	return currentPage;

}

//-----------------------------------------------------------------------------

function getCarouselPageCount(carousel) {

	var content = carousel.querySelector('.carousel-contents');
	var items = carousel.querySelectorAll('.carousel-contents > div');
	var itemWidth = items[0].offsetWidth;
	var itemSpacing = parseInt(window.getComputedStyle(content).gap);
	var carouselWidth = carousel.offsetWidth;
	var itemCount = items.length;
	var itemsPerPage = Math.floor(carouselWidth/(itemWidth+itemSpacing));
	var pageCount = Math.floor(itemCount/itemsPerPage);

	return pageCount;

}

//-----------------------------------------------------------------------------

function initialiseSliders() {

	// Establish the number of required indicators.
	var sliders = document.querySelectorAll('.slider');
	sliders.forEach(slider => {

		// Get image count.
		var images = slider.querySelectorAll('img');
		var imageCount = images.length;

		// Get current image index.
		var currentImage = -1;
		for (var i=0; i < imageCount; i++) {
			if(images[i].classList.contains('active')) {
				currentImage = i;
			}
		}
		if( currentImage == -1 ) {
			currentImage = 0;
			images[0].classList.add('active');
		}

		// Remove existing indicators.
		var indicators = slider.querySelectorAll('.slider-indicators > div');
		indicators.forEach(indicator => {
			indicator.remove();
		}
		);

		// Add new indicators.
		for (var i = 0; i < imageCount; i++) {
			const indicator = document.createElement('div');
			indicator.attributes['data-image'] = i;
			indicator.addEventListener('click', (event) => {
				event.preventDefault();
				jumpSliderTo(slider, event.target.attributes['data-image']);
			});
			if( i == currentImage ) {
				indicator.classList.add('active');
			}
			slider.querySelector('.slider-indicators').append(indicator);
		}

		// Update counter.
		var counter = slider.querySelector('.slider-counter');
		counter.innerHTML = (currentImage+1) + '/' + imageCount;

		//Establish a timer to automatically switch images.
		var sliderTimer = setInterval(function() {sliderRight(slider)}, 5000);

	});

}

//-----------------------------------------------------------------------------

function sliderLeft( slider ) {

	// Get image count.
	var images = slider.querySelectorAll('img');
	var imageCount = images.length;

	// Get current image index.
	var currentImage = -1;
	for (var i=0; i < imageCount; i++) {
		if(images[i].classList.contains('active')) {
			currentImage = i;
		}
	}

	// Switch to previous image.
	if(currentImage>0) {
		jumpSliderTo(slider, currentImage-1);
	} else {
		jumpSliderTo(slider, imageCount-1);
	}

}

//-----------------------------------------------------------------------------

function sliderRight( slider ) {

	// Get image count.
	var images = slider.querySelectorAll('img');
	var imageCount = images.length;

	// Get current image index.
	var currentImage = -1;
	for (var i=0; i < imageCount; i++) {
		if(images[i].classList.contains('active')) {
			currentImage = i;
		}
	}

	// Switch to next image.
	if(currentImage<(imageCount-1)) {
		jumpSliderTo(slider, currentImage+1);
	} else {
		jumpSliderTo(slider, 0);
	}

}

//-----------------------------------------------------------------------------

function jumpSliderTo( slider, imageIndex ) {

	// Get image count.
	var images = slider.querySelectorAll('img');
	var imageCount = images.length;

	// Get current image index.
	var currentImage = -1;
	for (var i=0; i < imageCount; i++) {
		if(images[i].classList.contains('active')) {
			currentImage = i;
		}
	}

	// Switch to specified image.
	if(currentImage!=imageIndex) {
		images[currentImage].classList.remove('active');
		images[imageIndex].classList.add('active');
	}

	// Update counter.
	var counter = slider.querySelector('.slider-counter');
	counter.innerHTML = (imageIndex+1) + '/' + imageCount;

	// Update indicators.
	var indicators = slider.querySelectorAll('.slider-indicators > div');
	indicators.forEach(indicator => {
		indicator.classList.remove('active');
	});
	indicators[imageIndex].classList.add('active');

}

//-----------------------------------------------------------------------------

function initialiseImageWithTextSliders() {

	// Establish the number of required indicators.
	var sliders = document.querySelectorAll('.image-with-text-slider');
	sliders.forEach(slider => {
		// Get slide count.
		var slides = slider.querySelectorAll('& > div:not(.slider-controls)');
		var slideCount = slides.length;

		// Get current slide index.
		var currentSlide = -1;
		for (var i=0; i < slideCount; i++) {
			if(slides[i].classList.contains('active')) {
				currentSlide = i;
			}
		}
		if( currentSlide == -1 ) {
			currentSlide = 0;
			slides[0].classList.add('active');
		}

		// Remove existing indicators.
		var indicators = slider.querySelectorAll('.slider-indicators > div');
		indicators.forEach(indicator => {
			indicator.remove();
		}
		);

		// Add new indicators.
		for (var i = 0; i < slideCount; i++) {
			const indicator = document.createElement('div');
			indicator.attributes['data-image'] = i;
			indicator.addEventListener('click', (event) => {
				event.preventDefault();
				jumpSliderTo(slider, event.target.attributes['data-image']);
			});
			if( i == currentSlide ) {
				indicator.classList.add('active');
			}
			slider.querySelector('.slider-indicators').append(indicator);
		}

		// Update counter.
		var counter = slider.querySelector('.slider-counter');
		counter.innerHTML = (currentSlide+1) + '/' + slideCount;

		//Establish a timer to automatically switch images.
		var imagWithTextSliderTimer = setInterval(function() {imageWithTextSliderRight(slider)}, 5000);

	});

}

//-----------------------------------------------------------------------------

function imageWithTextSliderLeft( slider ) {

	// Get slide count.
	var slides = slider.querySelectorAll('& > div:not(.slider-controls)');
	var slideCount = slides.length;

	// Get current slide index.
	var currentSlide = -1;
	for (var i=0; i < slideCount; i++) {
		if(slides[i].classList.contains('active')) {
			currentSlide = i;
		}
	}

	// Switch to previous slide.
	if(currentSlide>0) {
		jumpImageWithTextSliderTo(slider, currentSlide-1);
	} else {
		jumpImageWithTextSliderTo(slider, slideCount-1);
	}

}

//-----------------------------------------------------------------------------

function imageWithTextSliderRight( slider ) {

	// Get slide count.
	var slides = slider.querySelectorAll('& > div:not(.slider-controls)');
	var slideCount = slides.length;

	// Get current slide index.
	var currentSlide = -1;
	for (var i=0; i < slideCount; i++) {
		if(slides[i].classList.contains('active')) {
			currentSlide = i;
		}
	}

	// Switch to next image.
	if(currentSlide<(slideCount-1)) {
		jumpImageWithTextSliderTo(slider, currentSlide+1);
	} else {
		jumpImageWithTextSliderTo(slider, 0);
	}

}

//-----------------------------------------------------------------------------

function jumpImageWithTextSliderTo( slider, slideIndex ) {

	// Get slide count.
	var slides = slider.querySelectorAll('& > div:not(.slider-controls)');
	var slideCount = slides.length;

	// Get current slide index.
	var currentSlide = -1;
	for (var i=0; i < slideCount; i++) {
		if(slides[i].classList.contains('active')) {
			currentSlide = i;
		}
	}

	// Switch to specified slide.
	if(currentSlide!=slideIndex) {
		slides[currentSlide].classList.remove('active');
		slides[slideIndex].classList.add('active');
	}

	// Update counter.
	var counter = slider.querySelector('.slider-counter');
	counter.innerHTML = (slideIndex+1) + '/' + slideCount;

	// Update indicators.
	var indicators = slider.querySelectorAll('.slider-indicators > div');
	indicators.forEach(indicator => {
		indicator.classList.remove('active');
	});
	indicators[slideIndex].classList.add('active');

}

//-----------------------------------------------------------------------------

function getElementWidth(element) {

	var width = element.clientWidth;
	var computedStyle = getComputedStyle(element);
	width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

	return width;

}

//-----------------------------------------------------------------------------